<template>
  <div class="mall" ref="datePopper">
    <div class="mall-header">
      <div class="mall-hed1" v-if="phone && isLogin">
        <div class="mall-head mall-pub">
          <div class="mall-head-tit1">
            <van-icon class="mall-icon" color="#DB7A47" name="award" /><span>{{
              phone
            }}</span>
          </div>
          <div class="mall-head-tit2">
            可用积分：<span>{{ isPoints }}</span>
          </div>

        </div>
        <div class="mall-head mall-pub">
          <div class="mall-head-tit1">
            <van-icon class="mall-icon" name="cluster" color="#4FCBFF" />运营商：<span>{{ operator }}</span>
          </div>
          <div class="mall-head-tit2">
            <van-icon class="mall-icon" name="location" color="#DB7A47" />归属地：<span>{{ attribution }}</span>
          </div>
        </div>
      </div>
      <div class="mall-hed2" v-else>
        <div class="mall-seach">
          <van-field v-model.trim="mobile" type="digit" label="" maxlength="11" placeholder="请输入手机号" @input="telInput" />
        </div>
        <div class="mall-c" v-if="operatorShow">
          <div>
            所属运营商：<span>{{ operator }}</span>
          </div>
          <div>
            归属地：<span>{{ attribution }}</span>
          </div>
        </div>
        <div class="mall-seach">
          <van-field v-model="captcha" center clearable label="" type="digit" maxlength="6" placeholder="请输入短信验证码">
            <template #button>
              <span style="color: #f75d22; font-size: 12px; cursor: pointer" @click="getVerify()"
                v-html="countText"></span>
            </template>
          </van-field>
        </div>
      </div>
      <div class="mall-head mall-pub" style="padding-top: 4px">
        <div class="mall-head-tit1" style="padding-right: 30px" v-if="!phone">
          <van-button type="primary" color="#F75D22" block @click="mallLoginClick">登录</van-button>
        </div>
        <div class="mall-head-tit2" style="padding-right: 30px" v-else>
          <!-- <van-button
                type="primary"
                color="#718AF6"
                block
                @click='refreshClick'
              >刷新</van-button> -->
          <van-button type="primary" color="#DB7A47" block @click="outLoginClick">退出</van-button>
        </div>
        <div class="mall-head-tit2">
          <van-button type="primary" color="#718AF6" block @click="refreshClick">刷新</van-button>
        </div>
      </div>
      <div class="mall-hed-search">
        <input type="text" placeholder="最低积分" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
          v-model="listQuery.minRetailPrice"><span class="Bar">-</span>
        <input type="text" placeholder="最高积分" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
          v-model="listQuery.maxRetailPrice">
        <span class="clods" @click="inClose">搜索</span>
      </div>
      <div class="mall-hed-span">
        <span class="mall-span" @click="provinceClick" v-html="spanText"></span>

        <div class="img" @click="showSc1()"><span v-if="showSc">升序</span><span v-if="!showSc">降序</span><img
            src="../../assets/image/asc.png" v-if="showSc" alt=""><img src="../../assets/image/desc.png" v-if="!showSc"
            alt=""></div>
        <!-- <span class="mall-cen"></span>
                <span
                  class="mall-card"
                  @click="onBanner()"
                >号卡中心</span> -->
      </div>
    </div>

    <div class="section-mall">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" :offset="offset" finished-text="没有更多了" @load="onLoad">
          <div class="van-clearfix">
            <div class="mall-list" v-for="(item, index) in recommand" :key="index" @click="mallListClick(item)">
              <div class="mall-item">
                <span class="mall-dis">{{ item.discountName }}</span>
                <div class="mall-img">
                  <van-image round width="width=120px" fit="fill" :src="item.picUrl" />
                </div>
                <div class="mall-tit">
                  {{ item.name }}
                </div>
                <div class="mall-prict">
                  <div class="mall-prict-l">
                    <span>{{ item.retailPrice * item.userMin }}</span>积分
                  </div>
                  <div class="mall-prict-r">
                    <div class="mall-prict-n" v-if="item.num > 0">
                      {{ item.num }}
                    </div>
                    <!-- <van-icon
                      name="shopping-cart-o"
                      size="20"
                      :color="item.num > 0 ? variables.textColor : ''"
                      @click.stop="shopClick(item, index)"
                    /> -->
                  </div>
                </div>
                <!-- <div class="mall-foot">
                  <div class="mall-foot-btn">
                    <van-button
                        type="primary"
                        :color="variables.textColor"
                        round
                        size="small"
                        @click.stop="shopClick(item, index)"
                      >加入购物车</van-button>
                    <van-button
                      type="primary"
                      color="#165DFF"
                      round
                      size="small"
                      @click.stop="qrCodeClick(item)"
                    >扫码兑</van-button>
                  </div>
                  <div class="mall-foot-t">
                    <van-button
                      type="primary"
                      :color="variables.textColor"
                      round
                      size="small"
                      @click.stop="fastClick(item)"
                    >核销订单</van-button>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <!-- //省份选择 -->
    <van-popup v-model="screenShow" position="bottom" :style="{ height: '60%' }" @close="onClose">
      <div class="sereen-main">
        <div class="sereen-cen">
          <div class="serren-m">
            <div v-for="(item, index) in provinceList" :key="index"
              :class="{ isActive: checkCategory.includes(item.value) }" class="view-t" @click="screenClick(item, index)">
              {{ item.lable }}
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- //登录时加载状态 -->
    <van-overlay :show="isCurLOgin" class-name="loginOverlay">
      <div class="wrapper">
        <div class="" style="text-align: center">
          <!-- <van-loading
                size="30px"
                color="#fff"
              >登录中...</van-loading> -->
        </div>
      </div>
    </van-overlay>
    <!-- //返回顶部 -->
    <back-top></back-top>
    <van-popup v-model="cardShow" class="maxw" :style="{
      width: '88%'
    }">
      <div style="width: 100%; padding: 24px 16px; box-sizing: border-box">
        <div class="tan">
          <div class="entry-l-img" id="qrCode" ref="qrCodeDiv"></div>
        </div>
        <div class="qrTitle">发送短信兑换码<span>{{ briefCen }}</span> 到 <span>{{ briefTel
        }}</span>，短信兑换仅支持全积分兑换礼品(发送及接收短信免费)。
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import API from "../../api/http";
import { Dialog, Toast } from "vant";
import utils from "../../utils/index.js";
import GoodsSku from "@/components/goods-sku";
import variables from "@/styles/variables.scss";
import areaList from "@/utils/area";
import validateRule from "@/utils/validateRule";
import cast from "@/utils/cast";
import area from "@/utils/pcas-code.json";
import { setToken } from "@/utils/auth";
import BackTop from "@/components/back-top";
import QRCode from "qrcodejs2";
export default {
  components: { GoodsSku, BackTop },
  name: "mobile",
  inject: ["reload"],
  data() {
    return {
      showSc: true,
      areaList,
      offset: 60,
      overlayShow: false,
      value: "",
      integralSwitch: false,
      provinceList: [],
      mobile: "",
      captcha: "",
      points: 0,
      commodityid: "",
      // operator: "",
      // attribution: "",
      timer: null,
      count: 60,
      countText: "发送验证码",
      spanText: "请选择一个省份",
      cardShow: false,
      recommand: [],
      operatorShow: false,
      screenShow: false,

      loading: false,
      finished: false,
      refreshing: false,
      checkCategory: [],
      seachNum: 100,
      result: [],
      cardList: [],
      listQuery: {
        resource: 2,
        maxRetailPrice: this.$store.state.user.points || 0,
        minRetailPrice: 0,
        page: 1,
        limit: 500,
        // isHot: false,
        // isBuy: true,
        sort: "retail_price",
        order: "asc", //desc:将序  asc:升序
      },
      total: 0,
      radio: "",
      username: "",
      usertel: "",
      uservalue: "",
      userdetail: "",
      usersms: "",
      city: "", //市
      county: "", //区
      province: "", //省
      street: "",
      areaCode: "",
      userCount: 60,
      userText: "发送验证码",
      showArea: false,
      timer2: null,
      skuShow: false, //规格显示
      detailMap: {}, //当前产品信息
      showPopover: false,
      checkAddressId: "", //地址id
      searchResult: [],
      checkGoodls: "",
      orderId: "",
      smsCheck: false,
      isCurLOgin: false,
      options: [],
      activeNames: [],
      userNikename: "",
      userIdCard: "",
      goodIds: [],
      briefCen: "",
      briefTel: "10658999",
    };
  },
  computed: {
    variables() {
      return variables;
    },
    isLogin() {
      return this.$store.getters["user/isLogin"];
    },
    phone() {
      return this.$store.state.user.phone;
    },
    isPoints() {
      return this.$store.getters["user/isPoints"];
    },
    isPhone() {
      return this.$store.getters["user/isPhone"];
    },
    userType() {
      return this.$store.getters["user/userType"];
    },
    operator() {
      return this.$store.state.user.operator;
    },
    attribution() {
      return this.$store.state.user.attribution;
    },
    userId() {
      return this.$store.getters["user/userId"];
    },
  },

  created() {
    // this.getInit();

    this.$store.commit("tagsView/ADD_CACHED_VIEW", this.$route);

    // if (this.$route.params.typeDialag) {
    //   this.dialogShow();
    // }

    this.getProvince2();
    this.getProvince();
    if (this.isPhone) {
      this.orderId = "";
      this.usertel = this.phone;
      //   this.getAmount();
      this.getModileAddress(this.phone);
      this.listQuery.isBuy = true;
    }
  },
  activated() {
    if (this.isPhone) {
      this.usertel = this.phone;
      //   if (!this.$store.state.user.points) {
      //     this.getAmount();
      //   }

      // this.orderId = "";
      // this.getModileAddress(this.phone);
      this.listQuery.isBuy = true;
    }
  },
  mounted() {
    document.addEventListener("mouseup", this.setHeadPosition);
  },
  beforeDestroy() {
    document.removeEventListener("mouseup", this.setHeadPosition);
    // window.removeEventListener('mouseup', e => {}, true)
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "commodity" || to.name === "phoneCard") {
      this.$store.commit("tagsView/ADD_CACHED_VIEW", from); // 保存需要进行缓存的页面searchResult
      next();
    } else {
      this.$store.commit("tagsView/DEL_CACHED_VIEW", from);
      next();
    }
  },
  methods: {
    // <p style="margin:0">移动号卡业务：</p>
    //     <p style="margin:0">1、肥猫商城新增移动号卡业务，高佣立结</p>
    //     <p style="margin:0">2、套餐内容：29元/月(首月免月租)，首充50送50元话费，30G专享流量/月，套餐外5元1G月租通用流量，3个亲情号4人互打免费，全国接听免费,国内通话0.1元/分钟，短信0.1元/条，具体详情内容请咨询上级代理！！</p>
    dialogShow() {
      Dialog.alert({
        title: "京东E卡 注意事项：",
        show: true,
        messageAlign: "left",
        message: `
            <p style="margin:0">推广之前一定要清楚了解京东E卡使用说明</p>
            <p style="margin:0">用户使用E卡需要先注册京东账号，并实名认证。兑换成功的E卡不支持转赠，换成功的E卡不支持退卡。兑换成功后，请在30天内登录京东APP登录使用。</p>`,
      }).then(() => {
        // on close
      });
    },
    inClose() {
      console.log(this.$store.state.user.points);
      console.log(Number(this.listQuery.maxRetailPrice));
      if (this.$store.state.user.points) {
        if (
          Number(this.listQuery.maxRetailPrice) > this.$store.state.user.points
        ) {
          Toast("输入积分不可高于可用积分");
          return;
        }
        if (Number(this.listQuery.minRetailPrice) < 0) {
          Toast("输入积分不可低于0");
          return;
        }
      }
      this.recommand = [];
      this.listQuery.page = 1;
      this.getInit();
    },

    showSc1() {
      this.showSc = !this.showSc;
      if (this.showSc == false) {
        this.recommand = [];
        this.listQuery.order = "desc";
        this.listQuery.page = 1;
      } else {
        this.recommand = [];
        this.listQuery.order = "asc";
        this.listQuery.page = 1;
      }
      console.log(this.listQuery.order);
      this.getInit();
    },
    qux() {
      this.cardShow = false;
    },
    onBanner() {
      this.$router.push("/phoneCard");
    },
    setHeadPosition(e) {
      const that = this;
      console.log("mobile");
      if (that.$refs.datePopper) {
        // 点击其他地方关闭 popover气泡
        if (e.target.id !== "textarea") {
          that.showPopover = false;
        }
      }
      // 点击其他地方关闭 popover气泡
      // if (!that.$refs.datePopper.contains(e.target)) {
      //   // if (e.target.outerText === "清空") return;
      //   that.showPopover = false;
      // }
    },
    getProvince2() {
      let list = area;
      this.options = list.map((item) => {
        return {
          text: item.name,
          value: item.code,
          children: item.children.map((c) => {
            let clIst = [
              ...c.children,
              {
                name: "其他",
                code: c.code + 99,
                children: [],
              },
            ];

            return {
              text: c.name,
              value: c.code,
              children: clIst.map((m) => {
                //每个街道加一个  ‘其他的选项’
                return {
                  text: m.name,
                  value: m.code,
                  children: m.children
                    .map((t) => {
                      return {
                        text: t.name,
                        value: t.code,
                      };
                    })
                    .concat([
                      {
                        text: "其他",
                        value:
                          m.code +
                          (m.children.length + 1 > 10
                            ? m.children.length + 1
                            : 0 + m.children.length + 1),
                      },
                    ]),
                };
              }),
            };
          }),
        };
      });
    },
    getAmount() {
      API.getUserAmount().then((res) => {
        if (res.errno === 0) {
          this.$store.commit("user/SET_POINTS", res.data);
          this.points = res.data;
        }
      });
    },
    getModileAddress(val) {
      API.getMobileAddress({ mobile: val }).then((res) => {
        let map = {
          1: "移动",
          2: "联通",
          3: "电信",
        };
        this.operatorShow = true;
        let operator = map[res.data.mcase];
        let attribution = res.data.province + " " + res.data.city;
        this.$store.commit("user/SET_OPERATOR", operator);
        this.$store.commit("user/SET_ATTRIBUTION", attribution);
      });
    },
    getProvince() {
      let arr = Object.entries(areaList.province_list);
      for (let item of arr) {
        this.provinceList.push({
          value: item[0],
          lable: item[1],
        });
      }
    },

    getInit(val) {
      console.log("kkkk");
      API.getProductsList({ ...this.listQuery, keyword: "咪咕心跳" })
        .then((res) => {
          let data = res.data;
          console.log(data);
          this.loading = false;
          this.seachNum = data.total;
          if (data.list.length > 0) {
            let list = data.list.map((item) => {
              return {
                ...item,
                num: 0,
              };
            });
            if (data.pages === 1) {
              this.finished = true;
            }
            if (val) {
              this.refreshing = false;

              this.recommand = list;
            } else {
              this.recommand = this.recommand.concat(list);
            }

            this.listQuery.page++;
          } else {
            this.refreshing = false;
            this.finished = true;
          }
        })
        .catch(() => {
          this.refreshing = false;
          this.loading = false;
          this.finished = true;
        });
    },
    onRefresh() {
      //下拉刷星
      // 清空列表数据

      this.finished = false;
      this.refreshing = true;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.listQuery.page = 1;
      this.recommand = [];
      this.getInit(1);
    },
    onLoad() {
      if (this.refreshing) {
        this.recommand = [];
        this.refreshing = false;
      }
      console.log("onLoad");
      this.loading = true;
      this.getInit();
    },
    redioChange() {
      //单选
    },
    onRaioClick(item) {
      if (this.checkGoodls.productId === item.productId) {
        return;
      }
      this.uservalue = "";
      this.orderId = "";
      this.commodityid = item.commodityid;
      this.checkGoodls = item;
      console.log("hh", item);
    },
    boxChange(vals) {
      this.orderId = "";
    },
    getVerify() {
      // 验证手机号
      if (!this.mobile.trim()) {
        Toast("请输入手机号码");
        return;
      }
      if (!validateRule.checkPhoneAndTel(this.mobile)) {
        //手机号格式不正确
        Toast("手机号码输入错误");
        return;
      }
      const TIME_COUNT = 60; // 更改倒计时时间
      if (!this.timer) {
        // 发送验证码
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
        });
        API.postsendMGSms({ mobile: this.mobile })
          .then((res) => {
            Toast.clear();
            Toast("发送成功");
            this.count = TIME_COUNT;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
                this.countText = `${this.count}s`;
              } else {
                clearInterval(this.timer); // 清除定时器
                this.countText = "重新获取";
                this.count = 60;
                this.timer = null;
              }
            }, 1000);
          })
          .catch((err) => {
            Toast(err.errmsg);
          });
      }
    },
    onCheckClick() {
      if (!this.checkInfo()) {
        return;
      }
      if (!this.userNikename.trim()) {
        Toast("请输入真实姓名");
        return;
      }
      if (!validateRule.checkCard(this.userIdCard)) {
        Toast("你输入的身份证格式错误");
        return;
      }
      this.addAddress2();
    },

    async getVerify02() {
      if (!this.checkInfo()) {
        return;
      }
      let add = 0;
      if (!this.orderId) {
        try {
          await this.orderSubmit();
        } catch (err) {
          console.log("catch", err);
          this.checkAddressId = "";
          if (err.errno === 512) {
            Toast("该产品已限制,请选择其他产品！");
            this.cardList = this.cardList.filter(
              (item) => item.productId !== this.radio
            );
            this.radio = "";

            console.log("fghhh", this.radio, this.cardList);
            setTimeout(() => {
              this.onRefresh();
            }, 1500);
          }
          if (err.errno === 710) {
            this.cardList = this.cardList.filter(
              (item) => item.productId !== this.radio
            );
            this.radio = "";

            setTimeout(() => {
              this.onRefresh();
            }, 1500);
          }
          return;
        }
      } else {
        add = 1;
      }
      this.getSms(add);
    },
    checkInfo() {
      if (!this.phone) {
        Toast("请先登录");
        return false;
      }
      if (this.goodIds.length === 0) {
        Toast("请选择需要兑换的商品");
        return false;
      }

      return true;
    },
    getSms(e) {
      const TIME_COUNT = 60; // 更改倒计时时间

      if (!this.timer2) {
        // 发送验证码
        //  addressId: this.checkAddressId,
        let obj = {
          orderId: Number(this.orderId),
          count: e,
        };
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
        });
        API.postOrderSmsSend(obj)
          .then((res) => {
            Toast.clear();
            Toast("发送成功");
            this.smsCheck = true;
            this.timer2 = setInterval(() => {
              if (this.userCount > 0 && this.userCount <= TIME_COUNT) {
                this.userCount--;
                this.userText = `${this.userCount}s`;
              } else {
                clearInterval(this.timer2); // 清除定时器
                this.userText = "重新获取";
                this.userCount = 60;
                this.timer2 = null;
              }
            }, 1000);
          })
          .catch((err) => {
            Toast(err.errmsg);
          });
      }
    },
    telInput(val) {
      //正则过滤空格

      if (!validateRule.checkPhoneAndTel(val)) {
        //手机号格式不正确

        return;
      }
      this.getModileAddress(val);
    },
    mallLoginClick() {
      if (!this.mobile.trim()) {
        Toast("请输入手机号码");
        return;
      }
      if (!this.captcha.trim()) {
        Toast("请输入验证码");
        return;
      }
      let data = {
        mobile: this.mobile,
        captcha: this.captcha,
      };
      // 点击登录
      if (this.userType !== "") {
        //存在登录记录，再次登录
        //1、如果是渠道用户，调用login_by_phone接口
        //2、如果普通用户，调用login接口

        if (this.userType === cast.USER_LEVEL0) {
          //普通用户
          this.getLogin1(data);
        } else {
          this.getLogin2({
            ...data,
            mobileType: 4,
          });
        }
      } else {
        //没有登录记录,调用login接口
        this.getLogin1(data);
      }
    },
    getLogin1(data) {
      console.log(11);
      //没有登录时调用
      // type 1 正常用户 2 渠道用户
      // Toast.loading({
      //   message: "登录中...",
      //   forbidClick: true,
      //   duration: 0,
      // });

      this.isCurLOgin = true;

      this.$store
        .dispatch("user/login", {
          ...data,
          username: "",
          password: "",
          type: 1,
          mobileType: 1,
        })
        .then((res) => {
          this.$router.go(0);
          // this.listQuery.isBuy = true;
          // this.onRefresh();
          // this.overlayShow = false;
          // this.isCurLOgin = false;
          // clearTimeout();
        })
        .catch((err) => {
          console.log("失败", err);
          this.isCurLOgin = false;
          clearTimeout();
        });
    },
    getLogin2(data) {
      // Toast.loading({
      //   message: "登录中...",
      //   forbidClick: true,
      //   duration: 0,
      // });

      this.isCurLOgin = true;

      this.$store
        .dispatch("user/shopLogin", data)
        .then((res) => {
          // this.listQuery.isBuy = true;
          // this.onRefresh();
          // this.isCurLOgin = false;
          // this.overlayShow = false;
          // clearTimeout();
          this.$router.go(0);
        })
        .catch(() => {
          this.isCurLOgin = false;
          console.log("失败");
          clearTimeout();
        });
    },
    outLoginClick() {
      // 退出
      if (this.userType === cast.USER_LEVEL0) {
        //普通用户, 此处退出后 （商城、我的）都退出
        this.outLogin1();
      } else {
        //操作员/代理登录，此处退出后 ，只有（商城退出）
        this.outLogin2();
      }
    },
    outLogin1() {
      Dialog.confirm({
        title: "提示",
        message: "是否确定退出?",
      })
        .then(() => {
          // on confirm
          this.$store
            .dispatch("user/logout")
            .then(() => {
              this.$router.go(0);
            })
            .catch(() => {
              console.log("失败");
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    outLogin2() {
      Dialog.confirm({
        title: "提示",
        message: "是否确定退出?",
      })
        .then(() => {
          // on confirm
          API.loginout()
            .then((res) => {
              const { data } = res;
              this.$store.commit("user/SET_PHONE", "");
              this.$store.commit("user/SET_POINTS", "");
              utils.removeStore("phone");
              utils.removeStore("points");

              this.$store.commit("user/SET_TOKEN", data.token);
              setToken(data.token);

              this.$router.go(0);
            })
            .catch(() => { });
        })
        .catch(() => {
          // on cancel
        });
    },
    refreshClick() {
      //刷新
      this.mobile = "";
      this.captcha = "";
      this.count = "发送验证码";
      this.timer = null;
      this.checkCategory = [];
      this.spanText = "请选择一个省份";
      if (this.listQuery.regionCode) {
        this.$delete(this.listQuery, "regionCode");
      }

      this.onRefresh();
      this.reload();
    },
    screenClick(item) {
      // 筛选中分类点击事件
      if (this.checkCategory.includes(item.value)) {
        this.checkCategory = [];
        this.spanText = "请选择一个省份";
        this.$delete(this.listQuery, "regionCode");
      } else {
        this.checkCategory = [item.value];
        this.spanText = item.lable;
        this.listQuery.regionCode = item.value;
      }

      this.screenShow = false;

      this.onRefresh();
    },
    provinceClick() {
      this.screenShow = true;
    },
    onClose() {
      //关闭筛选弹窗
      this.screenShow = false;
    },
    async fastClick(item) {
      console.log("usertel", this.usertel);
      console.log("mobile", this.mobile);
      //核销订单
      let data = await this.getDetailRsync(item.id);
      console.log("getDetailRsync", data);
      API.postFastRsync({
        userId: this.userId,
        goodId: data.goodsId,
        mobile: this.mobile || this.usertel,
      }).then(() => {
        Toast("成功");
      });
    },
    getDetailRsync(id) {
      //获取商品详情
      const obj = {
        id: id,
      };
      let map = {};
      return API.getProductsDetail(obj).then((response) => {
        let info = response.data.info;
        let productList = response.data.productList;

        map = {
          ...productList[0],
          name: info.name,
          commodityid: info.brandId,
          number: 1,
          id: info.id,
          productId: productList[0].id,
          userMin: info.userMin,
          userMax: info.userMax,
        };
        return map;
      });
    },
    smsUrl(tel, content) {
      var link = `http://dhjf.juzitg.com/ttyh/sms.html?tel=${tel}&content=${content}`;

      return link;
    },
    qrCodeClick(item) {
      //扫码兑换
      let brief = item.brief.split(",");
      this.briefCen = brief[0];
      this.briefTel = brief[1];
      this.$nextTick(function () {
        const dom = "qrCode";
        const url = this.smsUrl(this.briefTel, this.briefCen);
        this.setQRCode(url, dom);
      });
      this.cardShow = true;
    },
    setQRCode(url, dom) {
      // 生成二维码
      const qrcode = document.getElementById(dom);

      // 清空旧数据，避免E二维码重复
      qrcode.innerHTML = "";
      new QRCode(qrcode, {
        text: url, // 二维码链接
        width: 110, // 二维码宽度
        height: 110, // 二维码高度
        colorDark: "#333333", // 二维码颜色
        colorLight: "#ffffff", // 二维码背景色
        correctLevel: QRCode.CorrectLevel.L, // 容错率，L/M/H
      });
    },
    shopClick(item, index) {
      // 加入购物车
      // item.num = item.num + 1;
      // let sum = this.integral - item.points;
      // this.$store.commit("user/SET_POINTS", sum);
      // this.integral = this.integral - item.points;
      // this.$set(this.recommand, index, item);
      this.getDetail(item.id);
    },
    getDetail(id) {
      console.log(id);
      //获取商品详情
      const obj = {
        id: id,
      };

      API.getProductsDetail(obj).then((response) => {
        console.log(response);
        // this.commodityid = response.data.info.brandId;
        // this.userMin = response.data.info.userMin;
        // this.userMax = response.data.info.userMax;
        // this.list = response.data.info;
        this.detailMap = response.data;
        let info = response.data.info;
        let productList = response.data.productList;
        if (productList.length > 1) {
          this.skuShow = true;
        } else {
          let map = {
            ...productList[0],
            name: info.name,
            commodityid: info.brandId,
            number: 1,
            id: info.id,
            productId: productList[0].id,
            userMin: info.userMin,
            userMax: info.userMax,
          };
          this.getAddCardList(map);
        }
      });
    },
    getAddCardList(data) {
      console.log(data);
      Toast("加入购物车成功");
      let flag = true;
      for (let item of this.cardList) {
        if (item.productId === data.productId) {
          item.number = item.number + 1;
          flag = false;
          break;
        }
      }
      if (flag) {
        this.cardList.push(data);
      }
    },
    onAddCartClicked(data) {
      //加入购物车
      let info = this.detailMap.info;
      let map = {
        ...data,
        id: info.id,
        name: info.name,
      };
      let productList = this.detailMap.productList;
      for (let v of productList) {
        if (v.id === data.productId) {
          map.url = v.url;
          break;
        }
      }
      this.getAddCardList(map);
      // API.addCart(item).then((res) => {
      //   Toast("加入购物车成功");
      // });
    },
    mallListClick(item) {
      // 查看详情
      //   this.$router.push({
      //     path: "/commodity",
      //     query: {
      //       id: item.id,
      //       type: "points",
      //     },
      //   });
      window.open(item.brief);
    },
    onChange(value, detail) {
      //添加数量
      for (let item of this.list) {
        if (item.id === detail.name) {
          item.num = value;
        }
      }
    },
    deleteGood(id) {
      //单个删除
      Dialog.confirm({
        title: "提示",
        message: "是否确定删除?",
      })
        .then(() => {
          // on confirm
          this.cardList = this.cardList.filter((item) => item.productId !== id);
        })
        .catch(() => {
          // on cancel
        });
    },
    onConfirm(values) {
      console.log(2221);
      this.uservalue = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.province = values[0].name;
      this.city = values[1].name;
      this.county = values[2].name;
      this.areaCode = values[2].code;
      this.userdetail = "";
      this.showArea = false;
    },
    onFinish({ selectedOptions }) {
      console.log(selectedOptions);
      // this.show = false;
      this.uservalue = selectedOptions.map((option) => option.text).join("/");

      this.province = selectedOptions[0].text;

      this.city = selectedOptions[1].text;
      this.county = selectedOptions[2].text;
      this.street = selectedOptions[3].text;
      this.areaCode = selectedOptions[2].value;
      // this.userdetail = "";
      this.showArea = false;
      let map = {
        brandId: this.commodityid,
        province: this.province,
        city: this.city,
        county: this.county,
        street: this.street,
      };
      if (this.commodityid) {
        API.postAddressCheckAreaLimit(map)
          .then((res) => {
            console.log(res.data);
            if (res.data == 1) {
              Dialog.alert({
                title: "提示",
                message: "受物流影响本地区已停止发货",
              });

              this.uservalue = "";
            } else if (res.data == 2) {
              Dialog.alert({
                title: "提示",
                message: "受物流影响本地区将延迟发货",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDetailInput(val) {
      if (val.trim() && this.province) {
        if (this.timerOut) {
          clearTimeout(this.timerOut);
          this.timerOut = null;
        }
        this.timerOut = setTimeout(() => {
          this.getAddress(val);
        }, 500);
      } else {
        clearTimeout(this.timerOut);
        this.timerOut = null;
        this.searchResult = [];
      }
    },
    getAddress(val) {
      var myGeo = new BMap.Geocoder();
      let that = this;
      myGeo.getPoint(
        val,
        function (point) {
          if (point) {
            console.log("ll", point);
            let map = new BMap.Map("allmap");
            map.centerAndZoom(new BMap.Point(point.lng, point.lat), 11);
            that.getSeach(map, val);
          }
        },
        this.province
      );
    },
    getSeach(map, val) {
      // let map = new BMap.Map("allmap");
      // map.centerAndZoom(new BMap.Point(116.331398, 39.897445), 11);
      // //创建地址解析器实例
      // // 将地址解析结果显示在地图上，并调整地图视野
      let that = this;
      var options = {
        renderOptions: { map: map },
        onSearchComplete: function (results) {
          console.log(1122);
          if (local.getStatus() === BMAP_STATUS_SUCCESS) {
            that.searchResult = [];
            that.showPopover = true;
            for (var i = 0; i <= results.getCurrentNumPois(); i++) {
              let m = results.getPoi(i);
              if (m) {
                let index = m.address.indexOf(that.county) || 0;
                let len = that.county.length;
                that.searchResult.push({
                  text: m.address.substring(index + len) + " " + m.title || "",
                  icon: "location-o",
                  className: "action-dom",
                });
              }
            }
            console.log("kkk", that.searchResult);
          } else {
            Toast("您输入的地址没有解析到结果！");
          }
        },
      };
      var local = new BMap.LocalSearch(map, options);
      let name = this.city + this.county + this.street + val;
      local.search(name);
    },
    onSelect(val) {
      //选中
      this.userdetail = val.text;
    },

    onSaveClick() {
      if (!this.phone) {
        Toast("请先登录");
        return;
      }
      if (this.goodIds.length === 0) {
        Toast("请选择需要兑换的商品");
        return false;
      }

      if (this.userText === "发送验证码") {
        Toast("请先获取验证码");
        return;
      }
      if (!this.usersms.trim()) {
        Toast("请输入验证码");
        return;
      }
      let map = {
        paysmsCode: this.usersms,
        orderId: Number(this.orderId),
      };
      // let map = {
      //   paysmsCode: "878225",
      //   orderId: 171,
      // };
      API.postMobilePay(map)
        .then((res) => {
          Toast("兑换成功");

          this.smsCheck = false;
          this.cardList = this.cardList.filter(
            (item) => item.productId !== this.radio
          );
          this.radio = "";
          this.usersms = "";
          window.clearInterval(this.timer2); // 清除定时器
          this.userText = "发送验证码";
          this.userCount = 60;
          this.timer2 = null;
          this.orderId = "";
          this.checkAddressId = "";
          // Dialog.confirm({
          //   title: "提示",
          //   message: "是否需要免费领取移动/联通 号卡",
          // })
          //   .then(() => {
          //     // on confirm
          //     this.$router.push("/phoneCard");
          //   })
          //   .catch(() => {
          //     // on cancel
          //   });
          setTimeout(() => {
            this.onRefresh();
            this.getAmount();
          }, 1000);
        })
        .catch((err) => {
          Toast(err.errmsg);
        });
      // await this.addAddress();
      // await this.orderSubmit({ chosenAddressId: this.checkAddressId });
    },
    onAgainClick() {
      //重新开始
      // this.username = "";
      // this.usertel = "";
      // this.uservalue = "";
      // this.userdetail = "";
      // this.usersms = "";
      // this.cardList = [];

      // this.operator = "";
      // this.attribution = "";

      // this.count = 60;
      // this.countText = "发送验证码";
      // this.timer = null;
      // clearInterval(this.timer);

      // this.userCount = 60;
      // this.userText = "发送验证码";
      // this.timer2 = null;
      // clearInterval(this.timer2);

      this.outLoginClick();
      // this.onRefresh();
    },
    addAddress2() {
      const obj = {
        id: this.checkAddressId || 0,
        pid: "48646",
        source: 1,
        city: this.city,
        county: this.county,
        province: this.province,
        name: this.username.trim(),
        tel: this.usertel.trim(),
        addressDetail: this.street + " " + this.userdetail,
        uservalue: this.uservalue,
        isDefault: false,
        areaCode: this.areaCode,
        realName: this.userNikename,
        idcard: this.userIdCard,
      };
      API.postAddressAdd(obj).then((res) => {
        this.checkAddressId = res.data;
        Toast(res.errmsg);
      });
    },
    addAddress() {
      //没有地址时，添加的地址调用 地址添加接口，以获取地址id

      const obj = {
        id: 0,
        pid: "",
        source: "",
        city: this.city,
        county: this.county,
        province: this.province,
        name: this.username.trim(),
        tel: this.usertel.trim(),
        addressDetail: this.street + " " + this.userdetail,
        uservalue: this.uservalue,
        isDefault: false,
        areaCode: this.areaCode,
        realName: "",
        idcard: "",
      };
      return API.postAddressAdd(obj).then((res) => {
        this.checkAddressId = res.data;
      });
    },
    getGoods() {
      // let map = "";
      // for (let v of this.cardList) {
      //   if (v.productId === this.radio) {
      //     map = v;
      //   }
      // }
      // return map;
      let arr = [];

      for (let v of this.cardList) {
        if (this.goodIds.includes(v.productId)) {
          arr.push({
            goodId: v.goodsId,
            productId: v.productId,
            number: v.number,
          });
        }
      }
      return arr;
    },
    orderSubmit() {
      let arr = this.getGoods();
      console.log("orderSubmit", arr);
      // goodId: map.goodsId,
      //  productId: map.productId,
      //   number: map.number,
      return API.submitNoAddr({
        goods: arr,
        addressId: this.checkAddressId,
      }).then((res) => {
        // Toast("兑换成功");
        // this.integralPopup = false;
        this.orderId = res.data.orderId;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";
@import "../../styles/mixin.scss";

.mall-pub {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 12px;
  color: #606060;
  background-color: #fff;
}

.mall-hed2 {
  .mall-seach {
    padding: 8px 12px;
    box-sizing: border-box;
  }

  .mall-seach>.van-cell {
    border-radius: 18px;
    border: 1px solid #ebebeb;
    overflow: hidden;
    line-height: 14px;
  }

  .mall-c {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    color: #a6a6a6;
    background: #fff;

    &>div {
      flex: 1;
    }

    span {
      color: $textColor;
    }
  }
}

.colll-span {
  cursor: pointer;
  color: $textColor;
  margin-left: 10px;
}

.man-banner {
  width: 100%;
  max-width: 750px;
  height: 130px;
  position: fixed;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  z-index: 11;
  text-align: center;

  img {
    width: 100%;
    height: 100%;
  }
}

.mall-hed-search {
  margin-bottom: 10px;
  display: flex;
  // align-items: center;
  padding: 5px 12px;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;

  .Bar {
    font-size: 18px;
    margin-right: 12px;
  }

  .clods {
    font-size: 14px;
    color: #fff;
    background-color: #f70;
    padding: 0 20px;
    border-radius: 5px;
    line-height: 25px;
  }

  input {
    width: 33%;
    margin-right: 10px;
    border-radius: 6px;
    text-align: center;
    background-color: #f2f2f2;
    border: none;
    font-size: 14px;
  }
}

.mall-hed-span {
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: space-between;
}

.mall-header {
  background-color: #fff;
  margin-bottom: 10px;
}

.img {
  display: inline-block;
  font-size: 14px;
  color: #f70;
  display: flex;
  align-items: center;

  img {
    width: 18px;
    height: 20px;
  }
}

.mall-span {
  display: inline-block;
  width: 141px;
  padding: 6px 0;
  background: #f2f2f2;
  border-radius: 6px;
  font-size: 14px;
  text-align: center;
  color: #949494;
  cursor: pointer;
}

.mall-hed-span .mall-cen {
  display: inline-block;
  flex: 1;
}

.mall-card {
  color: #fff;
  display: inline-block;
  width: 141px;
  padding: 6px 0;
  cursor: pointer;
  border-radius: 6px;
  font-size: 14px;
  text-align: center;
  background: $background-color;
  float: right;
}

.mall-head {
  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 11;
  font-size: 14px;
  background-color: #fff;
}

.mall-icon {
  margin-right: 5px;
}

.mall-seach {
  width: 100%;
  // margin-top: 36px;
  background-color: #fff;
}

.mall-head-tit1 {
  flex: 1;
}

.mall-head-tit2 {
  flex: 1;
}

.mall-head-tit3 {
  width: 60px;
  text-align: center;
}

.tagActive {
  /* font-size: 28px; */
  font-weight: 500;
  color: #585858;

  // border-bottom: 2px solid $textColor;
  &::after {
    font-weight: 500;
    color: #585858;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 32px;
    height: 2px;
    background-color: $background-color;
  }
}

.mall-list {
  width: 50%;
  float: left;
  padding: 0 6px;
  box-sizing: border-box;
}

.mall-item {
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  border-radius: 7px;
  margin-bottom: 10px;
  padding: 12px;
  box-sizing: border-box;
  position: relative;
}

.mall-item .mall-dis {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px;
}

.mall-img {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 15px;
}

.mall-img img {
  width: 100%;
  height: 100%;
}

.mall-tit {
  height: 40px;
  font-size: 15px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.mall-prict {
  display: flex;
  padding-top: 6px;
  align-items: center;
}

.mall-prict-l {
  flex: 1;
  font-size: 14px;
  color: $textColor;
  text-align: left;
}

.mall-prict-l>span {
  font-size: 16px;
  font-weight: 600;
}

.mall-prict-r {
  color: #bbbbbb;
  position: relative;
  line-height: 16px;
}

.mall-prict-n {
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: border-box;
  min-width: 16px;
  padding: 0 3px;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
  background-color: #ee0a24;
  border: 1px solid #fff;
  border-radius: 16px;
  -webkit-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  -webkit-transform-origin: 100%;
  transform-origin: 100%;
}

.mall-prict text {
  font-size: 16px;
}

.mall-foot {
  color: #bbbbbb;
  font-size: 12px;
  padding-top: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.mall-foot-btn {
  flex: 1;
}

.mall-foot-t {
  // width: 60px;
  width: 80px;
  text-align: right;
}

/* 弹框 */
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.block {
  width: 450px;
  padding: 40px 20px;
  background: #ffffff;
  border-radius: 6px;
}

.block .btn {
  margin-top: 20px;
  text-align: center;
}

/* //筛选弹框样式 */
.sereen-main {
  height: 100%;
  box-sizing: border-box;
  padding: 15px 15px 40px 15px;
  width: 100%;
  position: relative;
  overflow-y: scroll;
}

.sereen-cen {
  margin-bottom: 20px;
}

.serren-m {
  overflow: hidden;
}

.sereen-tit {
  font-size: 16px;
  margin-bottom: 24px;
  text-align: left;
}

.sereen-btn {
  width: 100%;
  position: fixed;
  text-align: right;
  bottom: 0px;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding: 10px;
  background: #fff;
}

.sereen-btn .van-button {
  margin-left: 12px;
  padding-left: 16px;
  padding-right: 16px;
}

.sereen-tit span {
  font-weight: 500;
  color: $textColor;
  position: relative;
  padding-bottom: 10px;
}

.sereen-tit span::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 32px;
  height: 2px;
  background-color: $background-color;
}

.view-t {
  display: inline-block;
  font-size: 14px;
  background-color: #f0f0f0;
  color: #969799;
  width: 75px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 15px;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.isActive {
  background-color: $textColor;
  color: #fff;
}

.cart-box {
  padding: 10px;

  .van-swipe-cell .van-swipe-cell__right {
    right: -1px;
  }

  .cart-body {
    margin: 0px 0 0px 0;
    background: #fff;
    border-radius: 10px;

    .good-item {
      display: flex;
      padding: 20px 6px;
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        width: 90%;
        margin: auto;
        border-top: 1px solid #f4f4f4;
      }

      // border: 1px solid #f4f4f4;
      .good-img {
        img {
          @include wh(100px, 100px);
        }
      }

      .good-desc {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        // padding: 20px 0;
        .good-title {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          font-size: 14px;
        }

        .good-btn {
          display: flex;
          justify-content: space-between;

          .price {
            font-size: 16px;
            color: $textColor;
            line-height: 28px;
          }

          .van-icon-delete {
            font-size: 20px;
            margin-top: 4px;
          }
        }
      }
    }

    .delete-button {
      width: 50px;
      height: 100%;
    }
  }

  .empty {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    margin-top: 200px;

    .empty-cart {
      width: 150px;
      margin-bottom: 20px;
    }

    .van-icon-smile-o {
      font-size: 50px;
    }

    .title {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  .submit-all {
    margin-bottom: 50px;

    .van-submit-bar__price,
    .van-submit-bar__suffix-label {
      color: $textColor;
    }

    .van-submit-bar__text {
      margin-right: 10px;
    }

    .van-submit-bar__button {
      background: $background-color;
    }
  }

  .van-checkbox__icon--checked .van-icon {
    background-color: $background-color;
    border-color: $background-color;
  }
}

.sec-shop {
  &>p {
    padding: 10px;
    color: #222222;
    font-size: 18px;
  }

  .sec-tit {
    padding: 10px;
    margin: 0 10px;
    box-sizing: border-box;
    display: flex;
    font-size: 14px;
    background: #ffffff;
    border-radius: 6px;

    &>div {
      flex: 1;

      span {
        color: $textColor;
      }
    }
  }
}

.sec-from {
  padding: 10px;

  .sec-form-btn {
    display: flex;
    padding: 10px 0;

    &>div:first-child {
      margin-right: 20px;
    }

    &>div {
      flex: 1;
    }
  }
}

.explain {
  width: 80%;
  position: absolute;
  text-align: center;
  max-width: 20rem;
  top: 10%;
  margin: auto;
  left: 0;
  right: 0;
}

.Mask {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  text-align: center;
  max-width: 20rem;
  padding: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
}

.qrTitle {
  font-size: 14px;

  &>span {
    color: #165dff;
  }
}

.tan {
  display: inline-block;
  text-align: center;
  margin: 20px 0;

  img {
    width: 100%;
    border-radius: 20px;
  }
}

.tan1 {
  text-align: center;

  img {
    width: 30px;
  }
}

.maxw {
  max-width: 935px;
  text-align: center;
}

.conents {
  position: fixed;
  height: 100%;
  width: 100%;

  top: 0;
  z-index: 12;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
}

.popup-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.popup-main {
  width: 90%;
  height: 520px;
  border-radius: 17px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  text-align: center;
  padding-bottom: 30px;
  z-index: 10;
}

.main-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
}

.cen-h>img {
  width: 20px;
  height: 7px;
}

.cen-h {
  color: #fff;
  font-size: 16px;
  padding-top: 12px;
}

.cen-h span {
  margin: 0 16px;
  vertical-align: middle;
}

.popup-cen {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 13;
}

.cen-m {
  font-size: 14px;
  text-align: left;
  padding: 20px;
  height: 100%;
}

.cen-m>div {
  padding: 5px 0;
}

.cen-m-img {
  text-align: center;
  height: 325px;
}

.cen-m-img img {
  width: 48%;
  height: 100%;
}

.img1 {
  padding-right: 5px;
}

.img2 {
  padding-left: 5px;
}

.cen-tt {
  text-align: center;
  color: #ff0000;
}

.popup-close {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -60px;
  margin: auto;
  text-align: center;
}

.popup-close>img {
  width: 40px;
  height: 40px;
}
</style>
    